<template>
  <div class="search__content-search">
    <!-- <img
      src="@/assets/images/icons/icon-serch.svg"
      alt=""
      class="search__icon-search"
      data-bs-dismiss="modal"
    /> -->
    <input
      v-model="searchGlobal"
      autocomplete="off"
      type="text"
      name="city"
      class="input input__input"
      :data-search="true"
      placeholder="Buscar vehículo"
      @focus="showSearch"
      @blur="handelSerch"
      v-on:input="debounceInput"
      id="input_search_enter"
    />
    <div
      class="search__resultSerch"
      :data-Show="opcionSerch"
      v-if="searchInputService !== ''"
    >
      <div
        class="search__resultSerch-list"
        v-for="item in searchInputService"
        :key="item.id"
        @click="serchParams(item)"
        data-bs-dismiss="modal"
      >
        {{ `${item.name}` }}
      </div>
    </div>
  </div>
</template>
<script>
import { ref, defineComponent, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import _ from "lodash";

export default defineComponent({
  name: "Autocomplete",

  props: {
    prop_searchGlobal: String,
  },
  emits: ["cityValue"],
  setup(props, { emit }) {
    const searchGlobal = ref("");
    const searchInput = ref([]);
    const searchInputService = ref([]);
    const opcionSerch = ref(false);
    const route = useRoute();
    const router = useRouter();
    
    const mod = ref(false);
  
    const showSearch = async () => {
      opcionSerch.value = true;
    };
    const handelSerch = () => {
      setTimeout(() => {
        opcionSerch.value = false;
      }, 500);
    };
    
    
    const serchParams = (value) => {
      console.log(value);
      searchGlobal.value = value.name;
      emit("cityValue", value.value);
    };
  


    const debounceInput = _.debounce(async () => {
      const { data } = await ApiService.get(
          `/api/cities?search=${searchGlobal.value}`
        );
        searchInputService.value = data.data.map((c, index) => {
          return { id: index + 1, value: c.city, name: c.name };
        });

        searchInput.value = [];
      
    }, 400);

    return {
     
      handelSerch,
      serchParams,
      searchGlobal,
      searchInput,
      opcionSerch,
      debounceInput,
      searchInputService,
      showSearch,
      mod,
    };
  },
});
</script>

<style scoped lang="scss">
.search {
  &__content-search {
    position: relative;
    width: 100%;
    input{
      width: 100%;
      height: 50px;
      background: #151317;
      border-radius: 5px;
      padding: 20px;
    }
  }
  &__content-search {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__icon-search {
    position: absolute;
    right: 15px;
    z-index: 2;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: 0.3s;
    }
  }
  &__resultSerch {
    background: #151317;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    width: 95%;
    top: 100%;
    display: none;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: #ff5b2011 #f3f3f300;
    scrollbar-width: thin;
    &::-webkit-scrollbar-thumb {
      background: #565656;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar{
      width: 5px;
    }
    
    &::-webkit-scrollbar-thumb:active {
      background-color: #565656;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #565656;
    }
    
     /* Estilos track de scroll */
    &::-webkit-scrollbar-track {
      background: #f3f3f300;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-track:hover, 
    &::-webkit-scrollbar-track:active {
      background: #00000000;
    }
    &[data-Show="true"] {
      display: flex;
      padding-left: 15px;
    }
  }
  &__resultSerch-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    padding: 8px;
    color: white;
    &:hover {
      background-color:#252427;
      font-weight: 400;
    }
  }
}
</style>
