
import { computed, defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import loaderClientVue from "../components/loader-client.vue";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import { FormInstance } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import AutoComplete from "@/views/client/components/Autocomplete.vue";

interface Step1 {
  year: string;
  brand: string;
  model: string;
  version: string;
  owners: number;
  km: string;
}
interface brand {
  id: string;
  name: string;
}
interface model {
  id: string;
  name: string;
}
interface year {
  id: string;
  name: string;
}
interface version {
  id: string;
  name: string;
}

interface Step2 {
  name: string;
  last_name: string;
  email: string;
  phone: string;
  city: string;
}

interface SellVehicle extends Step1, Step2 {}

export default defineComponent({
  name: "create-account-modal",
  components: {
    Field,
    ErrorMessage,
    loaderClientVue,
    AutoComplete
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const formRef = ref<HTMLFormElement | null>(null);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const valuateVehicle = ref<HTMLElement | null>(null);
    const createAccountModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const brands = ref<brand[]>([]);
    const models = ref<model[]>([]);
    const years = ref<year[]>([]);
    const route = useRoute();
    const router = useRouter();
    const versions = ref<version[]>([]);
    const showLoader = ref(false);
    const valuation = ref<any>("");
    const versionText = ref<any>("");
    const showSuccess = ref(false);
    const optionsCities = ref<any>([]);
    const list = ref<any>([]);
    const loading = ref(false);
    const agencyCities = ref<any>([]);
    const next_step = ref<any>(null);

    const has_agency = ref<boolean>(false);
    const has_appraiser = ref<boolean>(false);
    const interested_vehicle_id = ref<any>(0);

    const contact_data = ref<any>({
      email:"",
      phone:"",
    });

    const formData = ref<SellVehicle>({
      year: "Selecciona un año",
      brand: "Selecciona una marca",
      model: "Selecciona un modelo",
      version: "Selecciona una version",
      owners: 1,
      km: "",
      name: "",
      last_name: "",
      email: "",
      phone: "",
      city: ""
    });

    const createAppSchema = [
      Yup.object({
        year: Yup.string()
          .notOneOf(["Selecciona un año"], "Año es obligatorio")
          .required("Año es obligatorio")
          .label("Año"),
        brand: Yup.string()
          .notOneOf(["Selecciona una marca"], "La marca es obligatoria")
          .required("La marca es obligatoria")
          .label("Marca"),
        model: Yup.string()
          .notOneOf(["Selecciona un modelo"], "El modelo es obligatorio")
          .required("El modelo es obligatorio")
          .label("Modelo"),
        version: Yup.string()
          .notOneOf(["Selecciona una versión"], "La versión es obligatoria")
          .required("La versión es obligatoria")
          .label("Version"),
        owners: Yup.number()
          .required("La versión es obligatoria")
          .min(1, "Indica un numero correcto de dueños")
          .label("Version"),
        km: Yup.string()
          .typeError("El kilometraje debe de ser numérico")
          .required("El kilometraje es obligatorio")
          .label("Kilometraje")
      }),
      Yup.object({
        name: Yup.string().required("Nombre es obligatorio").label("Nombre"),
        last_name: Yup.string()
          .required("Los apellidos son obligatorios")
          .label("Apellidos"),
        city: Yup.string().required("La ciudad es obligatoria").label("Ciudad"),
        email: Yup.string()
          .required("El correo es obligatorio")
          .email("Ingresa un email valido")
          .label("Correo"),
        phone: Yup.string()
          .required("El Teléfono es obligatorio")
          .label("Teléfono")
      })
    ];

    const formatNumber = (value) => {
      let val = (parseInt(value) / 1).toFixed(0);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const priceFormat = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const phoneFormat = (value) => {
      let phone = value.split('');
      let phoneFormatT = "";
      phone.forEach((element, index) => {
        if(index == 2 || index == 5){
          phoneFormatT += element + "-";
        }else {
          phoneFormatT += element;
        }
      });
      return phoneFormatT;
    };

    const addKm = () => {
      let mileage = formData.value.km;
        mileage = formData.value.km.replace(" ", "");
        mileage = formData.value.km.replace("K", "");
        mileage = formData.value.km.replace("M", "");
        mileage = mileage.replace(/,/g, ""),
        mileage = formatNumber(mileage);
        mileage = mileage + " KM";
        
        formData.value.km = mileage;
    };

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const statusBar = computed(() => {
      if (totalSteps.value) {
        if (!isNaN(totalSteps.value)) {
          return ((currentStepIndex.value + 1) * 100) / totalSteps.value!;
        }
      }

      return 0;
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }      
      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit, setTouched } = useForm<Step1 | Step2>({
      validationSchema: currentSchema
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const valuate = async () => {
      try {
        const postData = {
          brand: formData.value.brand,
          year: formData.value.year,
          model: formData.value.model,
          version: formData.value.version
        };

        const { data } = await axios.post("/api/site/valuate", postData);
        valuation.value = data;
        
        return true;
      } catch (error) {
        Swal.fire({
          text: "Error al procesar los datos",
          icon: "error"
        });
        return false;
      }
    };

    const returned = () => {
      if (currentStepIndex.value == 0) {
        router.go(-1);
      } else {
        previousStep();
      }
    };

    const handleStep = handleSubmit(async (values) => {
      showLoader.value = true;
      if (currentStepIndex.value === 0) {
        resetForm({
          values: {
            ...formData.value
          }
        });
        setTouched({});
        const result = await valuate();
        if (!result) {
          return;
        }
      }
      if (!_stepperObj.value) {
        return;
      }
      if (currentStepIndex.value < _stepperObj.value.totatStepsNumber) {
        currentStepIndex.value++;
      }
      if (currentStepIndex.value === _stepperObj.value.totatStepsNumber) {
        await formSubmit();
      } else {
        _stepperObj.value.goNext();
      }
      showLoader.value = false;
    });

    const cityValuemit = (data) => {
      console.log("cityValue", data)
      formData.value.city = data;
      console.log("🚀 ~ file: SellVehicle.vue:898 ~ cityValue ~ formData.value.city", formData.value.city)
    };

    const formSubmit = async () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let mileage = formData.value.km;
          mileage = formData.value.km.replace(" KM", "");
          mileage = mileage.replace(/,/g, "");

      const dataValuation = {
        ...formData.value,
        brand: brands.value.find((b) => b.id == formData.value.brand)?.name,
        year: years.value.find((b) => b.id == formData.value.year)?.name,
        model: models.value.find((b) => b.id == formData.value.model)?.name,
        version: versions.value.find((b) => b.id == formData.value.version)?.name,
        City: formData.value.city,
        Email: formData.value.email,
        Mileage: mileage,
        Names: formData.value.name,
        Owners: formData.value.owners,
        Phone: formData.value.phone,
        Surnames: formData.value.last_name,
        Value: valuation.value,
      };
    

      try {
        const { data } = await axios.post("/api/site/valuations", dataValuation );
        console.log(data.data);

        if(data.data.vehicle != undefined){
          localStorage.setItem("appointment_customer", JSON.stringify({
            Email: formData.value.email,
            Names: formData.value.name,
            Phone: formData.value.phone,
            Surnames: formData.value.last_name,
            idState: data.data.valuation.idState,
            State: data.data.valuation.State,
            idCity: data.data.valuation.idCity,
            City: formData.value.city,
          }));
        }
        
        if(data.data.vehicle != undefined){
          localStorage.setItem("appointment_vehicle", JSON.stringify(data.data.vehicle));
        }

        let dataVehicle:any = await localStorage.getItem("appointment_vehicle");
        dataVehicle = JSON.parse(dataVehicle);
        versionText.value = dataVehicle.version;

        
        next_step.value = "with_out_dates";
        
        if(data.data.valuation != undefined){
          // localStorage.valuation = JSON.stringify(data.data.valuation);
          localStorage.setItem("appointment_valuation", JSON.stringify(data.data.valuation));
          
          if(data.data.valuation.Price != undefined){
            valuation.value = data.data.valuation.Price;
            (data.data.citas_agencia == true)? has_agency.value = true : false;
            (data.data.citas_ciudad == true)? has_appraiser.value = true : false;
            
            localStorage.setItem("extra_data", JSON.stringify({ 
              has_agency:has_agency.value,
              has_appraiser:has_appraiser.value,
              interested_vehicle_id:interested_vehicle_id.value,
            }));
            
            if(data.data.citas_disponibles == true && data.data.valuation.Price > 0){
              next_step.value = "success_valuation";
            } else if(data.data.citas_disponibles == true && data.data.valuation.Price == 0){
              next_step.value = "valuation_zero";
            }
          }
        }

        showSuccess.value = true;

        // Swal.fire({
        //   text: "Guardado satisfactoriamente",
        //   icon: "success"
        // }).then(() => {
        //   showSuccess.value = true;
        //   // console.log("show success");
        // });
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          text:typeof error.response.data.errors === "object" ? Object.values(error.response.data.errors).toString().replace(",", "</br>") : error.response.data.message,
        });
      }

      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    };

    resetForm({
      values: {
        ...formData.value
      }
    });

    const getContactData = async () => {
      try {
        const { data } = await axios.get("/api/site/contact-info/1");
        contact_data.value = data.data;
        console.log(data);

        return true;
      } catch (error) {
        Swal.fire({
          text: "Error al obtener los datos de contacto",
          icon: "error"
        });
        return false;
      }
    };

    const getBrands = async () => {
      let url = "/api/maxi/brands/v_1";
      try {
        const { data } = await ApiService.get(url);
        brands.value = data.data;
        console.log("🚀 ~ file: SellVehicle.vue:922 ~ getBrands ~ brands.value", brands.value)
      } catch (error) {
        console.log(error);
      }
    };

    const getModels = async () => {
      models.value = [];
      years.value = [];
      versions.value = [];
      formData.value.model = "Selecciona un modelo";
      formData.value.year = "Selecciona un año";
      formData.value.version = "Selecciona una versión";
      
      if(formData.value.brand != "Selecciona una marca"){
        showLoader.value = true;
        
        try {
          let url = "/api/maxi/models/" + formData.value.brand;
          const { data } = await ApiService.get(url);
          models.value = data.data;
        } catch (error) {
          console.log(error);
        }

        showLoader.value = false;
      }
    };

    const getYears = async () => {
      years.value = [];
      versions.value = [];
      formData.value.year = "Selecciona un año";
      formData.value.version = "Selecciona una versión";
      
      if(formData.value.model != "Selecciona un modelo"){
        showLoader.value = true;

        try {
          let url = `/api/maxi/years/${formData.value.model}`;
          const { data } = await ApiService.get(url);
          years.value = data.data;
        } catch (error) {
          console.log(error);
        }

        showLoader.value = false;
      }
    };

    const getVersions = async () => {
      console.log("Entra");
      showLoader.value = true;
      versions.value = [];
      formData.value.version = "Selecciona una versión";
      
      if(formData.value.model != "Selecciona un año"){
        showLoader.value = true;
        
        try {
          let url = `/api/maxi/versions/${formData.value.year}`;
          const { data } = await ApiService.get(url);
          console.log(data);
          versions.value = data.data;
        } catch (error) {
          console.log(error);
        }
        
        showLoader.value = false;
      }
    };
    
    const setVersion = computed(() => {
      return versions.value.find((v) => v.id == formData.value.version);
    });

    const model = computed(() => {
      return models.value.find((m) => m.id == formData.value.model);
    });

    const brand = computed(() => {
      return brands.value.find((v) => v.id == formData.value.brand);
    });

    const year = computed(() => {
      return years.value.find((v) => v.id == formData.value.year);
    });
    
    const cityIncludes = computed(() => {
      return agencyCities.value.includes(formData.value.city);
    });

    const remoteMethod = async (query: string) => {
      if (query) {
        loading.value = true;
        optionsCities.value = await getCities(query);
        loading.value = false;
      } else {
        optionsCities.value = [];
      }
    };

    onMounted(async () => {
      interested_vehicle_id.value = route.params.id;
      
      await getContactData();
      await getBrands();
      _stepperObj.value = StepperComponent.createInsance(
        valuateVehicle.value as HTMLElement
      );
      await getCitiesAgency();
    });

    const changecity = () => {
      console.log(formData.value);
    };
    
    const getCities = async (val) => {
      try {
        const { data } = await axios.get("/api/cities?search=" + val);
        return data.data.map((c) => {
          return { value: c.city, label: c.name };
        });
      } catch (error) {
        console.log(error);
      }
    };

    const getCitiesAgency = async () => {
      try {
        const { data } = await axios.get("/api/agency-cities");
        agencyCities.value = data.data;
        // console.log(data);

      } catch (error) {
        console.log(error);
      }
    };
    return {
      getContactData,
      changecity,
      valuateVehicle,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      priceFormat,
      currentStepIndex,
      cityIncludes,
      formData,
      createAccountModalRef,
      statusBar,
      remoteMethod,
      loading,
      optionsCities,
      getBrands,
      brands,
      getModels,
      models,
      getYears,
      years,
      getVersions,
      versions,
      showLoader,
      setVersion,
      model,
      brand,
      year,
      formRef,
      showSuccess,
      valuation,
      submitButtonRef,
      returned,
      addKm,
      next_step,
      has_agency,
      has_appraiser,
      contact_data,
      interested_vehicle_id,
      phoneFormat,
      versionText,
      cityValuemit,
    };
  }
});
